<template>

    <div  :loading="loading" class="parent-container"> 
  
       <!-- AG Grid Component -->
     <ag-grid-vue
     style="width: 100%; height: 100%;"
     :columnDefs="columnDefs"
     :defaultColDef="defaultColDef"
     :rowGroupPanelShow="'always'"
     :serverSideSortAllLevels="true"
     :serverSideOnlyRefreshFilteredGroups="true"
     :purgeClosedRowNodes="true"
     :serverSideInitialRowCount="10"
     :getServerSideGroupLevelParams="getServerSideGroupLevelParams"
     :rowModelType="rowModelType"
     :cacheBlockSize="cacheBlockSize"
     :maxBlocksInCache="maxBlocksInCache"
     :autoGroupColumnDef="autoGroupColumnDef"
     :getContextMenuItems="getContextMenuItems"
     :animateRows="true"
     :sideBar="sidebar"
     :rowSelection="'multiple'"
     :suppressRowClickSelection="false"
     :enableRangeSelection="true"
     :rowMultiSelectWithClick="false"
     @range-selection-changed="onRangeSelectionChanged"
     @cell-context-menu="onCellContextMenu"
     @row-selected="onRowSelected"
     @grid-ready="onGridReady"
     @filter-changed="onFilterChanged"
   ></ag-grid-vue>
  
   <!-- turn rowMultiSelectWithClick to true, if need multiple row selected at once  --> 
  
   </div>
  </template>
  
  
  <script>
  /* eslint-disable vue/no-unused-components */
  import { AgGridVue } from "ag-grid-vue";
  import 'ag-grid-enterprise';
  
  import TableHeader from "@/components/GridComponents/TableHeader.vue";
  import dateFormat from "dateformat";
  
  export default {
  name: "RawInventoryNew",
  components: {
   AgGridVue,
   agColumnHeader: TableHeader,
  },
  data: () => ({
   confirmDeletePallets: false,
   deletingPallets: false,
  
   // downloadingExport: false,
   // emailingExport: false,
   loading: false,
  
   search: null,
   searchHeaders: {},
   searchKey: 250,
   selectedPallets: [],
   selectedPalletKey: 100,
   stock: [],
  
   params: {
   offset: 0,
   limit: 1000000,
   total: 0,
  },
     columnDefs: [
       { headerName: "Status", field: "status", sortable: true,  width: 100, filter: 'agTextColumnFilter'},
       { headerName: "Location", field: "locationCode", sortable: true,  width: 100, enableRowGroup: true, filter: 'agTextColumnFilter' },
       { headerName: "Order No", field: "orderNo", enableRowGroup: true,  width: 100, sortable: true, filter: 'agNumberColumnFilter' },
       { headerName: "Barcode", field: "sscc", enableRowGroup: true,  sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Seq.", field: "sequenceNo", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Pal. Qty", field: "palletQuantity",  enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Ctns", field: "cartonQuantity", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Cust. Ref", field: "customerContainerRef", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter', width: 100 },
       { headerName: "Comm.", field: "commodityCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Var.", field: "varietyCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Grade", field: "grade", sortable: true, enableRowGroup: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Count", field: "countSize", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Pack", field: "packCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Mark", field: "mark", sortable: true, enableRowGroup: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Inv. Code", field: "inventoryCode", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Prod. Char", field: "productCharacteristic", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Market", field: "targetMarket", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Region", field: "targetRegion", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Country", field: "targetCountry", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "PUC", field: "puc", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Producer", field: "producerName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Orch.", field: "orchard", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "PHC", field: "phc", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Packhouse Name", field: "packhouseName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Insp. Date", field: "inspectionManifestDate", enableRowGroup: true, sortable: true, filter: true,  width: 100 },
       { headerName: "Insp. Age", field: "inspectionAge", enableRowGroup: true, sortable: true, filter: true,  width: 100},
       { headerName: "Orig. Intake Waybill", field: "originalWaybillNo", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100},
       { headerName: "Intake Waybill", field: "waybillNo", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Loc. Intake Date", field: "intakeTime", enableRowGroup: true, sortable: true, filter: true,  width: 100 },
       { headerName: "Loc. Intake Age", field: "age", enableRowGroup: true, sortable: true, filter: true,  width: 100 },
       { headerName: "Nett", field: "nettWeight", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Gross", field: "grossWeight", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Document Name", field: "documentName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Document Time", field: "batchFileTime", enableRowGroup: true, sortable: true, filter: true,  width: 100 }
     ],
     
     defaultColDef: {
       flex: 1,
       minWidth: 100,
       resizable: true,
       sortable: true,
       filter: true,
       enableRowGroup: true,
       enablePivot: true,
       // suppressSizeToFit: true,
     },
  
     sidebar: {
        toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            defaultToolPanel: '',
        },
        
     // statusBar: {
     //   statusPanels: [
     //     { statusPanel: "agTotalRowCountComponent", align: "left" },
     //     { statusPanel: "agFilteredRowCountComponent" },
     //     { statusPanel: "agSelectedRowCountComponent" },
     //   ],
     // },
  
     // gridApi: null,
     // gridColumnApi: null,
     selectedSSCC: null,
     sortModel: [],
     filterModel: {},
     rowBuffer: 0,
     cacheBlockSize: 50,
     rowModelType: "serverSide",
     maxBlocksInCache: 2,
     gridApi: null,
  
     autoGroupColumnDef: {
     headerName: "Group",
     field: "groupKey",
     minWidth: 250,
     cellRendererParams: {
       suppressCount: true,
       innerRenderer: (params) => {
         const groupKey = params.value;
         if (params.node.level === 0) {
           const count = params.node.allChildrenCount || params.data?.count || 'Empty';
           return `${groupKey} (${count})`;
         } else {
           return groupKey;
         }
       },
     },
   },
  
  
  }),
  created() {
   this.rowBuffer = 0;
   this.rowModelType = "serverSide";
   this.cacheBlockSize = 50;
   this.maxBlocksInCache = 2;
  
  },
  
  
  mounted() {
  this.columnDefs = [
  { headerName: "Status", field: "status", sortable: true, minWidth: 220, filter: 'agTextColumnFilter',  width: 100
       
       // filterParams: { values: ['In Stock', 'Allocated', 'Confirmed', 'In Transit', 'Removed', 'Stuffed', 'Complete', 'Cancelled', 'Instruction Sent'  ], debounceMs: 500,}
       },
       { headerName: "Location", field: "locationCode", sortable: true, enableRowGroup: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Order No", field: "orderNo", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Barcode", field: "sscc", enableRowGroup: true,  sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Seq.", field: "sequenceNo", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Pal. Qty", field: "palletQuantity",  enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Ctns", field: "cartonQuantity", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Cust. Ref", field: "customerContainerRef", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Comm.", field: "commodityCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Var.", field: "varietyCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Grade", field: "grade", sortable: true, enableRowGroup: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Count", field: "countSize", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Pack", field: "packCode", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Mark", field: "mark", sortable: true, enableRowGroup: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Inv. Code", field: "inventoryCode", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Prod. Char", field: "productCharacteristic", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Market", field: "targetMarket", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Region", field: "targetRegion", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Target Country", field: "targetCountry", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "PUC", field: "puc", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Producer", field: "producerName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Orch.", field: "orchard", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "PHC", field: "phc", sortable: true, enableRowGroup: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Packhouse Name", field: "packhouseName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Insp. Date", field: "inspectionManifestDate", enableRowGroup: true, sortable: true, filter: true, valueFormatter: this.formatInspDate,  width: 100 },
       { headerName: "Insp. Age", field: "inspectionAge", enableRowGroup: true, sortable: true, filter: true,  width: 100},
       { headerName: "Orig. Intake Waybill", field: "originalWaybillNo", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100},
       { headerName: "Intake Waybill", field: "waybillNo", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Loc. Intake Date", field: "intakeTime", enableRowGroup: true, sortable: true, filter: true, valueFormatter: this.formatIntakeTime,  width: 100 },
       { headerName: "Loc. Intake Age", field: "age", enableRowGroup: true, sortable: true, filter: true,  width: 100 },
       { headerName: "Nett", field: "nettWeight", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Gross", field: "grossWeight", enableRowGroup: true, sortable: true, filter: 'agNumberColumnFilter',  width: 100 },
       { headerName: "Document Name", field: "documentName", enableRowGroup: true, sortable: true, filter: 'agTextColumnFilter',  width: 100 },
       { headerName: "Document Time", field: "batchFileTime", enableRowGroup: true, sortable: true, filter: true, valueFormatter: this.formatDocumentTime,  width: 100 }
     ];
   },
  
  
  methods: {
   async deletePallets() {
   if (this.selectedPallets.length === 0) {
     this.$message.warning('No pallets selected for deletion.');
     return;
   }
  
   try {
     this.deletingPallets = true;
     console.log('Deleting pallets:', this.selectedPallets);
  
     let data = await this.$API.deleteStock(this.selectedPallets);
     console.log('API Response:', data);
  
     this.$message.success('Pallets Deleted Successfully');
  
     // Clear selected pallets
     this.selectedPallets = [];
  
     // Refresh the grid
     this.gridApi.deselectAll();
     this.gridApi.refreshServerSide({ purge: true });
  
   } catch (error) {
     console.error('Error deleting pallets:', error);
     this.$message.error('Failed to delete pallets');
   } finally {
     this.deletingPallets = false;
   }
  }, 
  
   async downloadExport() {
     this.downloadingExport = true;
     let data = await this.$API.getStockExport();
     var hiddenElement = document.createElement("a");
     hiddenElement.href =
       "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
       data;
     hiddenElement.target = "_blank";
     hiddenElement.download =
       "Pallet_Overview_" + new Date().getTime() + ".xlsx";
     hiddenElement.click();
     this.downloadingExport = false;
   },
   async emailExport() {
     try {
       this.emailingExport = true;
       let data = await this.$API.getStockExportEmail();
       this.emailingExport = false;
       this.$message.success("Export Email Sent Successfully");
     } catch (e) {
       this.$message.error("Error Sending Email");
       this.emailingExport = false;
     }
   },
  
   getContextMenuItems(params) {
       let result = params.defaultItems.slice();
       result.push('separator');
       result.push({
         name: 'Delete Selected Pallets',
         icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: #729fd9; margin-bottom: 0px; padding-bottom: 0px">delete</span>',
         action: () => {
           this.deletePallets();
         },
         disabled: this.selectedPallets.length === 0,
       });
       return result;
     },
  
  // #region test 
  onRowSelected(event) {
   const selectedNodes = this.gridApi.getSelectedNodes();
   this.selectedPallets = selectedNodes.map((node) => node.data.id);
   console.log('Selected Pallets:', this.selectedPallets);
  },
  
  onCellContextMenu(params) {
   // Check if the node is already selected
   if (!params.node.isSelected()) {
     // Select the right-clicked row without clearing other selections
     params.node.setSelected(true); // clearSelection defaults to false
   }
   // If the node is already selected, do nothing
  },
  
  onRangeSelectionChanged(event) {
   const cellRanges = this.gridApi.getCellRanges();
   console.log('Selected Cell Ranges:', cellRanges);
   
  },
  
  
  onGridReady(params) {
  this.gridApi = params.api;
  
  // Create server-side datasource
  const datasource = {
   getRows: async (params) => {
     try {
       const requestParams = {
         startRow: params.request.startRow,
         endRow: params.request.endRow,
         sortModel: JSON.stringify(params.request.sortModel || []),
         filterModel: JSON.stringify(params.request.filterModel || {}),
         rowGroupCols: JSON.stringify(params.request.rowGroupCols || []),
         groupKeys: JSON.stringify(params.request.groupKeys || [])
       };
  
       const response = await this.$API.getActiveStockOverview(requestParams);
  
       // Ensure the response has rowData and lastRow
       if (response && response.data && response.lastRow !== undefined) {
         console.log("Data returned from the server:", response.data);
  
         // Ensure the groupKey is correctly mapped in the response data
         const rowData = response.data.map((row) => ({
           groupKey: row.groupKey || row.status,  // Ensure groupKey is present
           group: !!row.groupKey, // Mark it as a grouped row if groupKey exists
           ...row  // Spread other fields in case needed
         }));
  
         params.success({
           rowData,
           rowCount: response.lastRow
         });
       } else {
         console.error("No data or lastRow in response");
         params.fail();
       }
     } catch (error) {
       console.error("Error fetching data:", error);
       params.fail();
     }
   }
  };
  
  // Register the datasource with the grid
  this.gridApi.updateGridOptions({ serverSideDatasource: datasource });
  },
  
   onSortChanged() {
     // Trigger the data source to reload based on new sort configuration
     this.gridApi.refreshServerSide({ purge: true });
   },
  
  
   onFilterChanged() {
     // When filter changes, reload the server side store
     this.gridApi.refreshServerSide({ purge: true });
   },
  
   getServerSideGroupLevelParams(params) {
   return params.level === 0
     ? { cacheBlockSize: 100 } // Root level
     : { cacheBlockSize: 50 };  // Child level
  },
  
   populateFilterValues(filters) {
  this.gridApi.getColumns().forEach(col => { 
   const field = col.getColDef().field;
   if (filters[field]) {
     col.getColDef().filterParams = {
       values: filters[field], // Populate unique filter values
     };
   }
  });
  this.gridApi.refreshHeader();
  },
  
  
  getRowId(params) {
  // Ensure each row has a unique ID
  if (params.data.groupKey) {
   return `group-${params.data.groupKey}`;  // Use groupKey for grouped rows
  } else if (params.data.id) {
   return `row-${params.data.id}`;  // Use unique ID for row-level data
  } else {
   return null;  // Fallback in case ID is missing, though this shouldn't happen
  }
  },
  
  
   formatIntakeTime(params) {
     return params.value ? this.$Format.formatDate(params.value).isoFormat : '';
   },
   
   formatDocumentTime(params) {
     return params.value ? this.$Format.formatDate(params.value).dateTime : '';
   },
   
   formatInspDate(params) {
     return params.value ? this.$Format.formatDate(params.value).isoFormat : '';
   },
  
  },
  };
  </script>
  
  <style scoped>
  .containerMessage {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .parent-container{
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #4d4d4d;
  
  }
  
  
  </style>